<script>
    import Header from '@/template/header.svelte';
    import Footer from '@/template/footer.svelte';
    import SvelteMarkdown from 'svelte-markdown';

    const privacyMarkdown = `
# Privacy Policy

Welcome to BuyMyNudes. This privacy policy outlines how we collect, use, disclose, and protect your personal information and data on our platform. By using our services, you agree to the terms of this privacy policy.

## 1. Information We Collect

We collect various types of information to enhance security, improve our services, and provide a tailored user experience:

a) Personal Information:
   - Name, email address, and date of birth (for age verification)
   - Payment information (processed securely through third-party payment processors)
   - Profile information you choose to provide

b) Automatically Collected Information:
   - Device metrics and IP addresses
   - Browser type and version
   - Site usage data and interaction with content
   - Geolocation data (country level only)

c) User-Generated Content:
   - Any content you upload, post, or create on our platform

## 2. How We Use Your Information

We use the collected information for the following purposes:

- To provide, maintain, and improve our services
- To process transactions and manage your account
- To verify your identity and ensure you meet our age requirements
- To communicate with you about your account and our services
- To prevent fraud and enhance the security of our platform
- To comply with legal obligations

## 3. Information Sharing and Disclosure

We do not sell your personal information. We may share your information in the following circumstances:

- With your consent
- With service providers who assist in our operations (e.g., payment processors, hosting services)
- To comply with legal obligations or respond to lawful requests
- In connection with a merger, sale, or acquisition of all or a portion of our company

## 4. Use of Third-Party Services

We utilize services from third parties to enhance our platform's functionality and security:

- Sentry for error tracking and performance monitoring
- Cloudflare for content delivery, anti-bot measures (Turnstile), and web analytics
- AWS Rekognition for content verification and moderation

These services may collect and process certain data as described in their respective privacy policies.

## 5. User Content and Responsibility

As a platform for user-generated content, you retain ownership of the content you create and upload. However, by using our services, you grant us a license to use, store, and share your content as described in our Terms of Service. You are responsible for ensuring your content complies with our community standards and applicable laws.

## 6. Data Retention and Deletion

We retain your personal information for as long as necessary to provide our services and comply with legal obligations. You can request deletion of your account and associated data by contacting our support team.

## 7. Your Rights and Choices

Depending on your location, you may have certain rights regarding your personal information:

- Access and update your personal information
- Request deletion of your data
- Object to or restrict certain processing of your data
- Data portability

To exercise these rights, please contact us through the methods provided in the "Contact Information" section.

## 8. Data Protection and Security

We implement various technical and organizational security measures to protect your personal data, including:

- Encryption of data in transit and at rest
- Regular security audits and vulnerability assessments
- Employee training on data protection and privacy

However, please be aware that no security measures are completely infallible.

## 9. International Data Transfers

Your information may be transferred to and processed in countries other than your own. We ensure appropriate safeguards are in place to protect your information in compliance with applicable data protection laws.

## 10. Changes to Privacy Policy

We may update this privacy policy from time to time. We will notify you of any significant changes through our website or via email. We encourage you to review this policy periodically.

## 11. Contact Information

If you have any questions, concerns, or requests regarding this privacy policy or our data practices, please contact us at:

Email: [privacy@buymynudes.com](mailto:privacy@buymynudes.com)

## 12. Governing Law

This privacy policy is governed by and construed in accordance with the laws of Germany. Any disputes relating to this policy will be subject to the exclusive jurisdiction of the courts of Germany.

Last updated: 02.08.2024
    `;
</script>

<Header />
<main class="prose mx-auto flex max-w-7xl flex-col px-4 py-8 text-black dark:text-white sm:px-6 lg:px-8">
    <SvelteMarkdown source="{privacyMarkdown}" />
</main>
<Footer />
